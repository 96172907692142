<template>
  <div class="footer">
    <div class="footer1">
      <div class="footer-top">
        <div class="footer-top1">
          <div class="top1">
            <span>产品中心</span>
            <p>智慧气象服务</p>
            <p>气象大数据可视化</p>
            <p>气象防灾减灾服务</p>
            <p>高影响天气预警服务</p>
          </div>
          <div class="top2">
            <p>气象灾害普查服务</p>
            <p>气象服务三维GIS</p>
            <p>查看更多&gt;&gt;</p>
          </div>
          <div class="top4">
            <span>华象云图</span>
            <p>公司信息</p>
            <p>企业文化</p>
            <p>加入我们</p>
            <p>联系我们</p>
          </div>
        </div>
      </div>
      <div class="footer2">
        <div class="top5">
          <span>联系我们</span>
          <!-- <p>客服热线：400-9669-253</p> -->
          <p>联系方式：<i class="el-icon-user"> 15353712215</i></p>
          <!-- <p>
            微信公众号<i class="el-icon-caret-bottom"></i> 官方微博<i
              class="el-icon-caret-bottom"
            >
              对公账号<i class="el-icon-caret-bottom"></i
            ></i>
          </p> -->
          <img src="../assets/img/二维码.f72a9c50.jpg" />
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <span
        >深圳华象云图科技有限责任公司版权所有Copyright@2019-2030
        AIIRight Reserved</span
      >
      <p>公司地址：深圳市罗湖区黄贝街道怡景社区怡景路1053峰景台大厦2栋3E</p>
      <p>西安研发中心：陕西省西安市莲湖区北关正街19号天宝安远国际</p>
      <p>
        <a href="https://beian.miit.gov.cn/"
          >粤ICP备2022014590号</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
body {
  margin: 0;
}

.but {
  margin-top: 30px;
  margin-left: 80px;
}

.el-button--primary {
  width: 95px;
  height: 45px;
  color: #000;
  background-color: #fff;
}

.solution {
  padding-top: 10px;
  width: 100%;
  background: #f7f7f7;
  padding-bottom: 160px;
  margin-top: 20px;
}

.solutionA {
  width: 60%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: -90px;
}

.solutionA p:first-of-type {
  font-size: 35px;
  color: #000;
  margin-bottom: 15px;
}

.solutionA p:nth-of-type(2) {
  text-align: center;
  color: rgba(0, 0, 0, 0.5803921568627451);
  font-size: 23px;
  margin-top: 6px;
}

.solutionA img {
  text-align: center;
  width: 90px;
  margin-bottom: 14px;
}

.solutionB {
  display: flex;
  margin-right: 45px;
  margin-top: 110px;
}

.portfolio {
  width: 520px;
  height: 270px;
  margin-left: 45px;
  border: 1px solid #fff;
  position: relative;
  border: 15px solid #e5e7ee;
  border-radius: 12px;
}

.portfolio-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.portfolio-image img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 0.6s;
  -ms-transition: all 0.8s;
  filter: blur(1px);
}

.portfolio-image img:hover {
  transform: scale(1.2);
  -ms-transform: scale(1.2);
}

.portfolio-content p {
  font-size: 20px;
  margin-left: 105px;
  line-height: 85px;
}

.footer {
  width: 100%;
  height: 510px;
}

.footer1 {
  width: 100%;
  display: flex;
}

.footer1,
.footer-top {
  background: #363b46;
  height: 340px;
}

.footer-top1 {
  display: flex;
}

.top1 span {
  color: #fff;
  height: 21px;
  font-size: 15px;
  margin-bottom: 25px;
  margin-left: 150px;
  line-height: 150px;
}

.top1 p:first-of-type {
  margin-top: -30px;
}

.top1 p:first-of-type,
.top1 p:nth-of-type(2),
.top1 p:nth-of-type(3),
.top1 p:nth-of-type(4) {
  font-size: 10px;
  color: #9599a3;
  cursor: pointer;
  margin-left: 150px;
}

.top2 {
  margin-left: 180px;
  margin-top: 150px;
}

.top2 p:first-of-type {
  margin-top: -26px;
}

.top2 p:first-of-type,
.top2 p:nth-of-type(2),
.top2 p:nth-of-type(3) {
  font-size: 10px;
  color: #9599a3;
  cursor: pointer;
}

.top3 {
  margin-left: 35px;
}

.top3 span {
  color: #fff;
  height: 21px;
  font-size: 15px;
  margin-bottom: 25px;
  margin-left: 150px;
  line-height: 150px;
}

.top3 p:first-of-type {
  margin-top: -30px;
}

.top3 p:first-of-type,
.top3 p:nth-of-type(2),
.top3 p:nth-of-type(3) {
  font-size: 10px;
  color: #9599a3;
  cursor: pointer;
  margin-left: 150px;
}

.top4 {
  margin-left: 185px;
}

.link {
  display: flex;
  margin-left: 120px;
  margin-top: 50px;
  color: #fff;
}

.link li:nth-of-type(2),
.link li:nth-of-type(3),
.link li:nth-of-type(4),
.link li:nth-of-type(5),
.link li:nth-of-type(6),
.link li:nth-of-type(7),
.link li:nth-of-type(8) {
  margin-left: 40px;
}

.top4 span {
  color: #fff;
  height: 21px;
  font-size: 15px;
  margin-bottom: 25px;
  line-height: 150px;
}

.top4 p:first-of-type {
  margin-top: -30px;
}

.top4 p:first-of-type,
.top4 p:nth-of-type(2),
.top4 p:nth-of-type(3),
.top4 p:nth-of-type(4) {
  font-size: 10px;
  color: #9599a3;
  cursor: pointer;
}

.top5 {
  margin-left: 230px;
}

.top5 span {
  color: #fff;
  height: 21px;
  font-size: 15px;
  margin-bottom: 25px;
  line-height: 130px;
}

.top5 p:first-of-type {
  margin-top: -30px;
}

.top5 p:first-of-type,
.top5 p:nth-of-type(2),
.top5 p:nth-of-type(3) {
  font-size: 10px;
  color: #9599a3;
  cursor: pointer;
}

.top5 img {
  width: 110px;
  height: 110px;
  margin-top: 20px;
}

.footer-bottom {
  background: #2c3038;
  width: 100%;
  height: 170px;
  text-align: center;
  color: #666;
  font-size: 14px;
}

.footer-bottom span {
  font-size: 14px;
  line-height: 70px;
  color: #666;
}

.footer-bottom p:first-of-type {
    margin-top: -11px;
}
.footer-bottom a{
    color: #666;
    text-decoration: none;
}
.footer-top p {
    line-height: 30px;
}
</style>