<template>
  <div>
    <Head />
    <router-view />
    <Foot />
  </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Foot from "@/components/Foot.vue";

export default {
  components: {
    Head,
    Foot,
  },
};
</script>

<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
</style>
