<template>
  <div class="hello">
    <img src="../assets/img/透明底.b8111eae.png" class="logo" alt="" />
    <ul class="top_ul">
      <li v-for="(item, index) in head" :key="index" class="top_list">
        <router-link :to="item.link">{{ item.name }}</router-link
        ><i v-if="item.cion != ''" :class="item.icon"></i>
        <div class="hover_box" v-if="item.children.length">
          <ul class="inside_ul">
            <li v-for="(ret, num) in item.children" :key="num" class="inside_li">
              <router-link :to="item.link"
                ><p>{{ ret }}</p></router-link
              >
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {},
  data() {
    return {
      head: [
        {
          name: "首页",
          children: [],
          icon: "",
          link: "/",
        },
        {
          name: "核心技术",
          children: [],
          icon: "",
          link: "/",
        },
        {
          name: "解决方案",
          children: [
            "智慧气象服务",
            "气象大数据可视化",
            "气象防灾减灾服务",
            "气象灾害普查服务",
            "高影响天气预警服务",
            "气象服务三维GIS",
          ],
          icon: "el-icon-arrow-down",
          link: "/Programme",
        },
        {
          name: "企业动态",
          children: [],
          icon: "",
          link: "/",
        },
        {
          name: "关于我们",
          children: [],
          icon: "",
          link: "/",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped>
.hello {
  height: 100px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.logo {
  float: left;
  width: 100px;
  height: 80px;
  margin-left: 8%;
  margin-top: 10px;
}
.top_ul {
  width: 65%;
  float: right;
  margin-right: 12%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.top_list {
  line-height: 100px;
  list-style: none;
  position: relative;
}
.top_list a {
  text-decoration: none;
  font-size: 20px;
  font-weight: 400;
  color: black;
}
.inside_ul {
  position: absolute;
  top: 100;
  left: -35px;
  width: 170px;
  padding: 10px 0;
  display: none;
  z-index: 999;
  background-color: white;
  box-shadow: 0px 0px 3px black;
  border-radius: 5px;
}
.inside_ul li {
  list-style: none;
}
.inside_ul li a,
.inside_ul li p {
  line-height: 30px;
  width: 100%;
  font-size: 14px;
  text-align: center;
}
.top_list:hover .inside_ul {
  display: block;
}
.inside_li:hover {
  background-color: rgba(54, 59, 70, 0.2);
}
</style>
