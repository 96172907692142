<template>
  <div class="home">
    <!-- <Head /> -->
    <video
      src="../assets/img/video_index.webm"
      loop="loop"
      autoplay="autoplay"
      muted="muted"
      class="ship"
    ></video>
    <div class="content">
      <p>公司简介</p>
      <p>Introduction</p>
      <img
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHIAAAADCAYAAABPo1sZAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjkzN0YzQjc0NjM1NTExRTY5NTFCOUUxMTVCMzUyQzQ2IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjkzN0YzQjc1NjM1NTExRTY5NTFCOUUxMTVCMzUyQzQ2Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OTM3RjNCNzI2MzU1MTFFNjk1MUI5RTExNUIzNTJDNDYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OTM3RjNCNzM2MzU1MTFFNjk1MUI5RTExNUIzNTJDNDYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz41PwgtAAAAPElEQVR42mL8//8/w0CBDRs2DJzlVAQBAQGMA+0Gxm079w9YYP789mE4xCMDO5fAgLuBcTRHDo8cCRBgAPJiFCtis6AVAAAAAElFTkSuQmCC"
        alt=""
      />
      <p>
        华象云图科技作为一家致力于气象智能化服务的高新科技企业，总部位于深圳市，在西安设有研发中心。
        公司利用云计算、大数据、人工智能、移动互联网等先进技术服务气象行业，具有很强的气象专家团队和产品研发能力，能够提供智慧气象服务、气象防灾减灾等全流程解决方案，旨在智能化、精细化服务于气象领域各类应用场景，实现企业社会价值。
      </p>
    </div>
    <div class="culture">
      <div class="cultureright">
        <span>华象云图文化</span><span>Culture</span>
        <p></p>
        <p><i class="el-icon-thumb"></i> 公司使命：科技助力气象</p>
        <p>
          <i class="el-icon-s-claim"></i> 公司愿景：成为气象行业最值得信赖的企业
        </p>
        <p>
          <i class="el-icon-s-promotion"></i>
          价值观：客户至上、务实谦逊、正直诚信、合作共赢、追求卓越
        </p>
        <img
          src="../assets/img/qiye3.f7cbf155.png"
          alt=""
        />
      </div>
      <div class="cultureleft">
        <span>华象云图动态</span><span>Dynamic</span>
        <p></p>
        <p><i class="el-icon-share"></i> 敬请期待</p>
        <img
          src="../assets/img/qiye2.550dc733.png"
          alt=""
        />
      </div>
    </div>
    <div class="solution">
      <div class="solutionA">
        <p>解决方案</p>
        <p>Solution</p>
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHIAAAADCAYAAABPo1sZAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjkzN0YzQjc0NjM1NTExRTY5NTFCOUUxMTVCMzUyQzQ2IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjkzN0YzQjc1NjM1NTExRTY5NTFCOUUxMTVCMzUyQzQ2Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OTM3RjNCNzI2MzU1MTFFNjk1MUI5RTExNUIzNTJDNDYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OTM3RjNCNzM2MzU1MTFFNjk1MUI5RTExNUIzNTJDNDYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz41PwgtAAAAPElEQVR42mL8//8/w0CBDRs2DJzlVAQBAQGMA+0Gxm079w9YYP789mE4xCMDO5fAgLuBcTRHDo8cCRBgAPJiFCtis6AVAAAAAElFTkSuQmCC"
          alt=""
        />
      </div>
      <div class="solutionB">
        <div class="portfolio">
          <div class="portfolio-image">
            <img
              src="../assets/img/解决方案1.406657f3.png"
              alt=""
            />
          </div>
          <div class="portfolio-content">
            <p>智慧气象服务</p>
          </div>
        </div>
        <div class="portfolio">
          <div class="portfolio-image">
            <img
              src="../assets/img/解决方案2.b3afc1ab.png"
              alt=""
            />
          </div>
          <div class="portfolio-content">
            <p>气象大数据可视化</p>
          </div>
        </div>
        <div class="portfolio">
          <div class="portfolio-image">
            <img
              src="../assets/img/解决方案3.51b006c1.png"
              alt=""
            />
          </div>
          <div class="portfolio-content">
            <p>气象防灾减灾服务</p>
          </div>
        </div>
      </div>
      <div class="solutionB">
        <div class="portfolio">
          <div class="portfolio-image">
            <img
              src="../assets/img/解决方案4.f24461c0.png"
              alt=""
            />
          </div>
          <div class="portfolio-content">
            <p>气象灾害普查服务</p>
          </div>
        </div>
        <div class="portfolio">
          <div class="portfolio-image">
            <img
              src="../assets/img/解决方案5.72e3cbc1.png"
              alt=""
            />
          </div>
          <div class="portfolio-content">
            <p>高影响天气预警服务</p>
          </div>
        </div>
        <div class="portfolio">
          <div class="portfolio-image">
            <img
              src="../assets/img/解决方案6.77f5d67f.png"
              alt=""
            />
          </div>
          <div class="portfolio-content">
            <p>气象服务三维GIS</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Head from '@/components/Head.vue'

export default {
  name: "HomeView",
  components: {
    // Head
  },
};
</script>

<style scoped>
body {
    margin: 0
}

.el-menu-demo {
    display: flex
}

.logo {
    width        : 115px;
    height       : 80px;
    margin-left  : 138px;
    margin-right : 485px;
    margin-bottom: 5px
}

.el-icon-arrow-down {
    font-size: 20px
}

.el-menu.el-menu--horizontal {
    border-bottom: 1px solid #fff
}

.el-menu-item {
    transition: none
}

.el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: 2px solid #000;
    height       : 85px
}

.el-menu--horizontal>.el-menu-item {
    margin-left: 15px;
    color      : #000
}

.el-menu-demo {
    margin-top: 15px
}

.index1 {
    margin-left     : 550px;
    font-size       : 16px;
    background-color: #fff;
    line-height     : 80px;
    margin-top      : 10px
}

.index2 {
    margin-left: 25px
}

.el-dropdown-link,
.index2 {
    font-size       : 16px;
    color           : #000;
    background-color: #fff;
    line-height     : 80px
}

.el-dropdown-link {
    margin-left: 20px;
    margin-top : 10px;
    cursor     : pointer
}

.el-dropdown {
    display    : inline-block;
    position   : relative;
    color      : #000;
    font-size  : 14px;
    margin-top : -10px;
    margin-left: 20px
}

.el-icon-arrow-down {
    font-size: 15px
}

.index3 {
    margin-left: 20px
}

.index3,
.index4 {
    font-size       : 16px;
    color           : #000;
    background-color: #fff;
    line-height     : 80px
}

.index4 {
    margin-left: 5px
}

.ship {
    width      : 80%;
    margin-left: 138px
}

.content {
    width     : 100%;
    height    : 335px;
    margin    : 0 auto;
    margin-top: 40px;
    text-align: center
}

.content p:nth-of-type(2) {
    text-align: center;
    color     : rgba(0, 0, 0, .5803921568627451);
    font-size : 21px;
    margin-top: 6px
}

.content p:first-of-type {
    color        : #000;
    font-size    : 35px;
    margin-bottom: 15px
}

.content p:nth-of-type(3) {
    margin     : 0 auto;
    width      : 736px;
    color      : #666a7b;
    text-align : left;
    font-size  : 17px;
    line-height: 45px
}

.content img {
    text-align   : center;
    width        : 90px;
    margin-bottom: 14px
}

.culture {
    width  : 100%;
    height : 750px;
    display: flex
}

.cultureright {
    width       : 50%;
    margin-top  : 100px;
    margin-right: 5px
}

.cultureleft {
    width       : 50%;
    margin-top  : 100px;
    margin-right: 51px
}

.cultureright img {
    width      : 585px;
    height     : 410px;
    margin-left: 85px;
    margin-top : 18px
}

.cultureright span:first-of-type {
    margin-top : 20px;
    font-size  : 25px;
    color      : #000;
    margin-left: 85px
}

.cultureright span:nth-of-type(2) {
    margin-top : 20px;
    font-size  : 20px;
    color      : rgba(0, 0, 0, .5803921568627451);
    margin-left: 310px
}

.cultureright p:first-of-type {
    width         : 540px;
    margin-left   : 85px;
    border-bottom : 1px solid #afafc7;
    padding-bottom: 6px
}

.cultureright p:nth-of-type(2),
.cultureright p:nth-of-type(3),
.cultureright p:nth-of-type(4) {
    color      : #666a7b;
    margin-left: 85px;
    margin-top : 20px;
    font-size  : 15px
}

.cultureleft span:first-of-type {
    margin-top : 20px;
    font-size  : 25px;
    color      : #000;
    margin-left: 85px
}

.cultureleft span:nth-of-type(2) {
    margin-top : 20px;
    font-size  : 20px;
    color      : rgba(0, 0, 0, .5803921568627451);
    margin-left: 290px
}

.cultureleft p:first-of-type {
    width         : 540px;
    margin-left   : 85px;
    border-bottom : 1px solid #afafc7;
    padding-bottom: 6px
}

.cultureleft p:nth-of-type(2) {
    color      : #666a7b;
    margin-left: 85px;
    margin-top : 60px;
    font-size  : 25px
}

.cultureleft img {
    width      : 585px;
    height     : 411px;
    margin-left: 85px;
    margin-top : 33px
}

.solution {
    padding-top   : 10px;
    width         : 100%;
    background    : #f7f7f7;
    padding-bottom: 160px
}

.solutionA {
    width        : 60%;
    margin       : 0 auto;
    text-align   : center;
    margin-bottom: -90px
}

.solutionA p:first-of-type {
    font-size    : 35px;
    color        : #000;
    margin-bottom: 15px
}

.solutionA p:nth-of-type(2) {
    text-align: center;
    color     : rgba(0, 0, 0, .5803921568627451);
    font-size : 25px;
    margin-top: 6px
}

.solutionA img {
    text-align   : center;
    width        : 90px;
    margin-bottom: 14px
}

.solutionB {
    display     : flex;
    margin-right: 45px;
    margin-top  : 110px
}

.portfolio {
    width        : 520px;
    height       : 270px;
    margin-left  : 45px;
    border       : 1px solid #fff;
    position     : relative;
    border       : 15px solid #e5e7ee;
    border-radius: 12px
}

.portfolio-image {
    width   : 100%;
    height  : 100%;
    overflow: hidden
}

.portfolio-image img {
    width         : 100%;
    height        : 100%;
    cursor        : pointer;
    transition    : all .6s;
    -ms-transition: all .8s;
    filter        : blur(1px)
}

.portfolio-image img:hover {
    transform    : scale(1.2);
    -ms-transform: scale(1.2)
}

.portfolio-content p {
    font-size  : 20px;
    /* margin-left: 105px; */
    text-align: center;
    line-height: 85px
}

.footer {
    width : 100%;
    height: 510px
}

.footer1 {
    width  : 100%;
    display: flex
}

.footer1,
.footer-top {
    background: #363b46;
    height    : 340px
}

.footer-top1 {
    display: flex
}

.top1 span {
    color        : #fff;
    height       : 21px;
    font-size    : 15px;
    margin-bottom: 25px;
    margin-left  : 150px;
    line-height  : 150px
}

.top1 p:first-of-type {
    margin-top: -30px
}

.top1 p:first-of-type,
.top1 p:nth-of-type(2),
.top1 p:nth-of-type(3),
.top1 p:nth-of-type(4) {
    font-size  : 10px;
    color      : #9599a3;
    cursor     : pointer;
    margin-left: 150px
}

.top2 {
    margin-left: 180px;
    margin-top : 173px
}

.top2 p:first-of-type {
    margin-top: -26px
}

.top2 p:first-of-type,
.top2 p:nth-of-type(2),
.top2 p:nth-of-type(3) {
    font-size: 10px;
    color    : #9599a3;
    cursor   : pointer
}

.top3 {
    margin-left: 35px
}

.top3 span {
    color        : #fff;
    height       : 21px;
    font-size    : 15px;
    margin-bottom: 25px;
    margin-left  : 150px;
    line-height  : 150px
}

.top3 p:first-of-type {
    margin-top: -30px
}

.top3 p:first-of-type,
.top3 p:nth-of-type(2),
.top3 p:nth-of-type(3) {
    font-size  : 10px;
    color      : #9599a3;
    cursor     : pointer;
    margin-left: 150px
}

.top4 {
    margin-left: 185px
}

.link {
    display    : flex;
    margin-left: 120px;
    margin-top : 50px;
    color      : #fff
}

.link li:nth-of-type(2),
.link li:nth-of-type(3),
.link li:nth-of-type(4),
.link li:nth-of-type(5),
.link li:nth-of-type(6),
.link li:nth-of-type(7),
.link li:nth-of-type(8) {
    margin-left: 40px
}

.top4 span {
    color        : #fff;
    height       : 21px;
    font-size    : 15px;
    margin-bottom: 25px;
    line-height  : 150px
}

.top4 p:first-of-type {
    margin-top: -30px
}

.top4 p:first-of-type,
.top4 p:nth-of-type(2),
.top4 p:nth-of-type(3),
.top4 p:nth-of-type(4) {
    font-size: 10px;
    color    : #9599a3;
    cursor   : pointer
}

.top5 {
    margin-left: 230px
}

.top5 span {
    color        : #fff;
    height       : 21px;
    font-size    : 15px;
    margin-bottom: 25px;
    line-height  : 130px
}

.top5 p:first-of-type {
    margin-top: -30px
}

.top5 p:first-of-type,
.top5 p:nth-of-type(2),
.top5 p:nth-of-type(3) {
    font-size: 10px;
    color    : #9599a3;
    cursor   : pointer
}

.top5 img {
    width     : 110px;
    height    : 110px;
    margin-top: 20px
}

.footer-bottom {
    background: #2c3038;
    width     : 100%;
    height    : 170px;
    text-align: center;
    color     : #666;
    font-size : 14px
}

.footer-bottom span {
    font-size  : 14px;
    line-height: 70px;
    color      : #666
}

.footer-bottom p:first-of-type {
    margin-top: -11px
}
.content:nth-child(2){
  text-indent:35px;
}
</style>